import { forwardRef } from 'react'
import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js'
import { RoutePaths } from 'route-paths'

interface Props {
  fullyDiscounted: boolean
  setPaymentOption: (option: string) => void
  stripeClientSecret: string
  stripeSubscriptionId: string
  setLoading: (loading: boolean) => void
  couponCode?: string | null
}

export const ExpressCheckout = forwardRef(
  ({
    fullyDiscounted,
    couponCode,
    stripeClientSecret,
    setLoading,
    stripeSubscriptionId,
  }: Props) => {
    const stripe = useStripe()
    const elements = useElements()

    if (fullyDiscounted) {
      return null
    }

    const onConfirm = async () => {
      setLoading(true)
      if (!stripe || !elements || !stripeClientSecret) {
        return
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: stripeClientSecret,
        confirmParams: {
          return_url: `${window.location.origin}${RoutePaths.ThankYou}?subscription_id=${stripeSubscriptionId}&coupon_code=${couponCode}`,
        },
      })

      if (error) {
        console.log(error)
      }

      setLoading(false)
    }

    return (
      <>
        <ExpressCheckoutElement
          onConfirm={onConfirm}
          options={{
            buttonType: {
              applePay: 'buy',
              googlePay: 'buy',
            },
            paymentMethods: {
              applePay: 'always',
              googlePay: 'always',
              link: 'never',
            },
            paymentMethodOrder: ['apple_pay', 'google_pay'],
            layout: {
              maxColumns: 1,
              maxRows: 2,
            },
          }}
        />
      </>
    )
  },
)

ExpressCheckout.displayName = 'ExpressCheckout'
