import {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import countryList from 'react-select-country-list'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Select from 'react-select'
import cardsImg from './cards.png'
import { RoutePaths } from 'route-paths'

interface Props {
  children?: ReactNode
  fullyDiscounted: boolean
  setPaymentOption: (arg0: string) => void
  paymentOption: string
  handleError: (arg0: Error | string) => void
  setLoading: (arg0: boolean) => void
  stripeClientSecret: string
  couponCode?: string | null
  userCountry?: string
  stripeSubscriptionId: string
}

interface CountrySelect {
  value: string
  label: string | null
}

type Ref = ReactNode | null

const cardElementOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#aab7c4',
      },
      padding: '10px 12px',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
}

interface ValidationResult {
  isValid: boolean
  errors: {
    firstName: boolean
    lastName: boolean
  }
}

export const CreditCard = forwardRef<Ref, Props>(
  (
    {
      fullyDiscounted,
      setPaymentOption,
      paymentOption,
      handleError,
      setLoading,
      userCountry,
      couponCode,
      stripeClientSecret,
      stripeSubscriptionId,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      submitPayment() {
        handleCc()
      },
    }))

    const { t } = useTranslation('translation', {
      useSuspense: false,
    })

    const stripe = useStripe()
    const elements = useElements()

    const formRef = useRef<HTMLFormElement>(null)

    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)

    const countryOptions = useMemo(() => countryList().getData(), [])

    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [country, setCountry] = useState<CountrySelect>(() => {
      if (!userCountry) {
        return {
          value: countryOptions[0].value,
          label: countryOptions[0].label,
        }
      } else {
        return {
          value: userCountry,
          label: countryList().valueMap[userCountry.toLowerCase()],
        }
      }
    })

    const selectOptions = (
      styles: any,
      {
        isDisabled,
        isFocused,
        isSelected,
      }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean },
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#00D186'
        : isFocused
        ? '#7fe8c2'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : '#2D2D2D',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#00D186' : '#7fe8c2'),
      },
    })

    const validateForm = (): ValidationResult => {
      const firstName = firstNameRef.current?.value?.trim()
      const lastName = lastNameRef.current?.value?.trim()

      const errors = {
        firstName: !firstName,
        lastName: !lastName,
      }

      setFirstNameError(errors.firstName)
      setLastNameError(errors.lastName)

      return {
        isValid: !Object.values(errors).some(Boolean),
        errors,
      }
    }

    const handleCc = async () => {
      if (!stripe || !elements) {
        return
      }

      setLoading(true)

      const { isValid } = validateForm()

      if (!isValid) {
        setLoading(false)
        handleError(t('errors.validation_error'))
        return
      }

      try {
        const { error: paymentError, paymentIntent } =
          await stripe.confirmCardPayment(stripeClientSecret, {
            return_url: `${window.location.origin}${RoutePaths.ThankYou}?subscription_id=${stripeSubscriptionId}&coupon_code=${couponCode}`,
            payment_method: {
              card: elements.getElement(CardElement)!,
            },
          })

        if (paymentError) {
          handleError(paymentError.message || t('errors.unexpected_error'))
          return
        }

        if (paymentIntent && paymentIntent.status === 'succeeded') {
          window.location.href = `${window.location.origin}${RoutePaths.ThankYou}?subscription_id=${stripeSubscriptionId}&payment_intent_client_secret=${stripeClientSecret}`
        }
      } catch (error) {
        handleError(t('errors.unexpected_error'))
      } finally {
        setLoading(false)
      }
    }

    return (
      <div className="rounded-lg p-6 shadow-checkout-button ring-1 ring-ar-white">
        <div
          className="flex cursor-pointer justify-between"
          onClick={() => setPaymentOption('cc')}>
          <h3 className="text-md font-title xl:text-sm">
            {t('checkout.card')}
          </h3>
          <img src={cardsImg} alt="Credit Cards" width="134" height="24" />
        </div>
        {!fullyDiscounted && (
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault()
              handleCc()
            }}
            className={`mt-8 flex flex-col justify-evenly space-y-4 ${
              paymentOption === 'cc' ? 'block' : 'hidden'
            }`}>
            <label
              htmlFor="first_name"
              className="font-body text-sm text-ar-dark-gray">
              {t('checkout.name')}
            </label>
            <div className="grid w-11/12 grid-cols-1 space-y-8 sm:grid-cols-2 sm:space-y-0 sm:space-x-6">
              <input
                type="text"
                id="first_name"
                onFocus={() => setFirstNameError(false)}
                ref={firstNameRef}
                placeholder={t('checkout.name_placeholder')}
                className={`rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none ${
                  firstNameError && 'border-red-400'
                }`}
              />
              <input
                type="text"
                id="last_name"
                onFocus={() => setLastNameError(false)}
                ref={lastNameRef}
                placeholder={t('checkout.last_name_placeholder')}
                className={`rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none ${
                  lastNameError && 'border-red-400'
                }`}
              />
            </div>
            <label className="font-body text-sm text-ar-dark-gray">
              {t('checkout.cc_number')}
            </label>
            <div className="w-11/12">
              <div className="relative rounded-lg border border-gray-300">
                <div className="px-4 py-4">
                  <CardElement
                    options={cardElementOptions}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <label className="font-body text-sm text-ar-dark-gray">
              {t('checkout.billing_info')}
            </label>
            <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6">
              <Select
                className="z-0 font-title text-sm text-ar-dark-gray"
                options={countryOptions}
                placeholder={t('checkout.country_placeholder')}
                value={country}
                onChange={(c) => setCountry(c as CountrySelect)}
                menuPlacement="top"
                styles={{
                  input: (provided) => ({
                    ...provided,
                    padding: '1rem',
                    margin: 0,
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '0.5rem',
                    border: '1px solid #d1d5db',
                    boxShadow: 'none',
                    '&:hover': {
                      border: '1px solid #d1d5db',
                    },
                  }),
                  option: selectOptions,
                }}
              />
              <input
                id="zip_code"
                className="rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none"
                type="text"
                placeholder={t('checkout.zipcode_placeholder')}
              />
            </div>
          </form>
        )}
      </div>
    )
  },
)
