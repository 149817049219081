import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import usePricing from 'hooks/usePricing'
import useSnowplow from 'hooks/useSnowplow'
import { PaymentPlan } from 'types/payment'

interface Props {
  currency: string
  onClick: () => void
  isLoading?: boolean
  plan: PaymentPlan
}

export const PlanBox: React.FC<Props> = ({
  plan,
  currency,
  onClick,
  isLoading = false,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const {
    trial,
    discountedPrice,
    totalDiscountedPrice,
    totalPrice,
    monthlyPrice,
    monthlyDiscountedPrice,
    campaignMonthlyPrice,
    rampPrice,
  } = usePricing(plan, currency, null)

  const interval = () => {
    switch (plan.intervalLength) {
      case 12:
        return t('annually')
      case 3:
        return t('quarterly')
      case 1:
        return t('monthly')
    }
  }

  const periodicallyTrialTranslated = () => {
    if (!trial?.amount) {
      return t('plans.billed_annually', {
        interval: interval(),
        price: totalPrice,
      })
    }

    return t(`plans.billed_with_trial.${trial.unit}`, {
      price: totalPrice,
      count: trial.amount,
    })
  }

  const billedPeriodicallyWithCoupon = () => {
    if (trial) {
      return periodicallyTrialTranslated()
    }

    return t('plans.billed_annually_with_discount', {
      interval: interval(),
      price: totalPrice,
      price_with_discount: totalDiscountedPrice,
    })
  }

  const billedPeriodically = () => {
    const interval = plan.intervalLength === 1 ? 'monthly' : 'annually'
    return t(`plans.billed_${interval}`, {
      price: rampPrice,
    })
  }

  const specialOffer = (price: string) => {
    const interval = plan.intervalLength === 1 ? 'month' : 'year'
    return t(`plans.first_${interval}_offer`, {
      price: price,
    })
  }

  const duration = () =>
    t('plans.months', {
      count: plan.intervalLength,
    })

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'add_to_cart',
      label: duration(),
    })
    onClick()
  }

  return (
    <div className="mt-5 justify-center sm:flex">
      <div className="box-content w-full border-2 border-ar-green bg-ar-white text-center md:flex md:text-left">
        <div className="md:mb-4 md:w-1/2 md:pl-8">
          <p className="mt-5 font-title text-sm uppercase text-ar-dark-gray">
            {duration()}
          </p>

          <p className="mt-4 font-link text-2xl text-ar-green line-through">
            {discountedPrice ? monthlyPrice : campaignMonthlyPrice}
          </p>

          <p className="mt-4 font-link text-2xl text-black">
            {`${discountedPrice ? monthlyDiscountedPrice : monthlyPrice} / ${t(
              'plans.month',
            )}`}
          </p>

          <p className="mt-4 font-body text-sm text-ar-dark-gray">
            {discountedPrice || trial
              ? billedPeriodicallyWithCoupon()
              : specialOffer(totalPrice)}
          </p>

          <p className="font-body text-sm text-ar-green line-through">
            {discountedPrice ? '' : billedPeriodically()}
          </p>

          <div className="mt-14 px-7 md:hidden">
            <button
              id={`add_to_cart_mobile_${plan.code}`}
              type="button"
              onClick={onButtonClick}
              disabled={isLoading}
              className={cx(
                'text-md m-auto mt-6 block w-full rounded-lg bg-ar-green p-5 text-center font-link text-white focus:outline-none',
                { 'opacity-50': isLoading },
              )}>
              {t('plans.call_to_action').toUpperCase()}
            </button>

            <div className="flex flex-col gap-4 pt-4 pb-10">
              <p className="font-body text-sm text-ar-dark-gray">
                {t('plans.cancel_anytime')}
              </p>
              <p className="font-title text-sm text-black">
                {t('plans.money_back')}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:ml-32 md:block md:w-1/2">
          <button
            id={`add_to_cart_desktop_${plan.code}`}
            type="button"
            onClick={onButtonClick}
            disabled={isLoading}
            className={cx(
              'text-md mt-12 block w-3/4 rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none',
              { 'opacity-50': isLoading },
            )}>
            {t('plans.call_to_action').toUpperCase()}
          </button>

          <div className="mt-4 flex flex-col gap-4">
            <p className="font-body text-sm text-ar-dark-gray">
              {t('plans.cancel_anytime')}
            </p>
            <p className="font-title text-sm text-black">
              {t('plans.money_back')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
  // }
}
