import { forwardRef, useImperativeHandle, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import paypalImg from './paypal.png'
import * as Sentry from '@sentry/react'
import { useStripe } from '@stripe/react-stripe-js'
import { RoutePaths } from 'route-paths'

interface Props {
  children?: ReactNode
  fullyDiscounted: boolean
  setPaymentOption: (arg0: string) => void
  paymentOption: string
  handleError: (arg0: Error | string) => void
  setLoading: (arg0: boolean) => void
  couponCode?: string | null
  stripeClientSecret: string
  stripeSubscriptionId: string
}

type Ref = ReactNode | null

export const PayPal = forwardRef<Ref, Props>(
  (
    {
      fullyDiscounted,
      setPaymentOption,
      paymentOption,
      handleError,
      setLoading,
      couponCode,
      stripeClientSecret,
      stripeSubscriptionId,
    },
    ref,
  ) => {
    const stripe = useStripe()
    const { t } = useTranslation('translation', {
      useSuspense: false,
    })

    useImperativeHandle(ref, () => ({
      submitPayment() {
        handlePaypal()
      },
    }))

    const handlePaypal = async () => {
      if (!stripe) {
        handleError('Stripe not initialized')
        return
      }

      setLoading(true)

      try {
        const { error } = await stripe.confirmPayPalPayment(
          stripeClientSecret,
          {
            return_url: `${window.location.origin}${RoutePaths.ThankYou}?subscription_id=${stripeSubscriptionId}&coupon_code=${couponCode}`,
          },
        )

        if (error) {
          handleError(error.message || t('errors.unexpected_error'))
          setLoading(false)
        }
      } catch (error) {
        Sentry.captureException(error)
        handleError(t('errors.unexpected_error'))
      } finally {
        setLoading(false)
      }
    }

    const onClick = () => {
      fullyDiscounted ? setPaymentOption('cc') : setPaymentOption('paypal')
    }

    return (
      <div className="rounded-lg p-6 shadow-checkout-button ring-1 ring-ar-white">
        <div className="flex cursor-pointer justify-between" onClick={onClick}>
          <h3 className="text-md font-title xl:text-sm">
            {t('checkout.paypal')}
          </h3>
          <img src={paypalImg} alt="PayPal" height="24" width="34" />
        </div>
        <p
          className={`mt-3 font-body text-sm
                          ${paymentOption === 'paypal' ? 'block' : 'hidden'}`}>
          {t('checkout.paypal_description')}
        </p>
      </div>
    )
  },
)
