import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import usePricing from 'hooks/usePricing'
import { Modal } from '.'
import { Plan } from 'pages/Plans'
import { Heading } from 'components/Heading'

interface Props {
  plan: Plan
  currency: string
  onClick: () => void
  onClose: () => void
  open: boolean
  isLoading?: boolean
}

export const SpecialOfferModal: React.FC<Props> = ({
  plan,
  currency,
  onClick,
  onClose,
  open,
  isLoading = false,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { totalPrice, rampPrice } = usePricing(plan, currency)

  const firstYearOffer = () => {
    return t('plans.first_year_offer', {
      price: totalPrice,
    })
  }

  return (
    <Modal onClose={onClose} open={open}>
      <div className="flex flex-col gap-3 px-6 py-10 text-center">
        <Heading
          text={t('plans.headline_modal').toUpperCase()}
          centered
          textColor="ar-dark-gray"
        />
        <p className="text-sm">
          {t('plans.months', { count: plan.intervalLength })}
        </p>
        <div className="text-md text-ar-green line-through">{rampPrice}</div>
        <div className="text-md font-link text-ar-dark-gray">
          {firstYearOffer()}
        </div>
        <button
          disabled={isLoading}
          id="add_to_cart_modal"
          type="button"
          onClick={onClick}
          className={cx(
            'text-md block w-full rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none',
            { 'opacity-50': isLoading },
          )}>
          {t('plans.call_to_action_alt')}
        </button>

        <p className="px-4 font-title text-sm text-black">
          {t('plans.money_back')}
        </p>
      </div>
    </Modal>
  )
}
