import { useState, useEffect } from 'react'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useFacebook from 'hooks/useFacebook'
import useSnowplow from 'hooks/useSnowplow'
import useStripePlanData from 'hooks/useStripePlanData'
import { useUser } from 'context/UserContext'
import { usePlan } from 'context/PlanContext'
import { Benefits } from 'components/Benefits'
import { ContinueButton } from 'components/ContinueButton'
import { FAQ } from 'components/FAQ'
import { Heading } from 'components/Heading'
import { PlanBox } from './PlanBox'
import { PlanBoxesLoading } from './PlanBoxesLoading'
import { Reviews } from './Reviews'
import { SubHeading } from 'components/SubHeading'
import { SpecialOfferModal } from 'components/Modal/SpecialOffer'
import { PaymentPlan } from 'types/payment'

interface Props {
  locale: string
  nextRoute: string
}

export const Plans: React.FC<Props> = ({ locale, nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const { setPlan } = usePlan()
  const { plans, currency, country } = useStripePlanData(locale)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { facebookTrack } = useFacebook()
  const { snowplowTrack } = useSnowplow()

  const trackInitiateCheckout = (plan: PaymentPlan) => {
    const numItems = 1
    const planCurrencyBased = plan.currencies.find(
      (c) => c.currency === currency,
    )
    const value = planCurrencyBased?.unitAmount!

    facebookTrack({
      userId: user.id!,
      eventName: 'InitiateCheckout',
      data: {
        content_ids: [plan.code],
        num_items: numItems,
        currency,
        value,
      },
    })

    snowplowTrack({
      eventName: 'initiate_checkout',
      property: currency,
      value: value?.toString() ?? '',
    })
  }

  const onContinue = async (plan: PaymentPlan) => {
    trackInitiateCheckout(plan)
    setLoading(true)

    try {
      setPlan({ ...plan })
      setUser({ ...user, country, currency })
      setLoading(false)
      navigate(`${nextRoute}?plan_id=${plan.code}`)
    } catch (error: any) {
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
    }
  }

  useEffect(() => {
    const displayModal = setTimeout(() => setShowModal(true), 15000)

    return () => clearTimeout(displayModal)
  }, [])

  return (
    <div className="flex h-full flex-col">
      {showModal && plans && plans[0] && (
        <SpecialOfferModal
          open={showModal}
          isLoading={loading}
          plan={plans[0]}
          currency={currency}
          onClick={() => onContinue(plans[0])}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className="absolute h-400 w-full bg-ar-green lg:rounded-b-4xl" />

      <div className="relative">
        <div className="m-auto px-8 pt-9 lg:w-2/3">
          <Heading text={t('plans.headline_1')} centered />
          <Heading text={t('plans.headline_2')} centered />

          <SubHeading text={t('plans.subheadline')} centered />

          {!plans?.length ? (
            <PlanBoxesLoading />
          ) : (
            plans.map((plan: PaymentPlan) => (
              <PlanBox
                key={plan.code}
                plan={plan}
                onClick={() => onContinue(plan)}
                currency={currency}
                isLoading={loading}
              />
            ))
          )}

          <Benefits />
          <Reviews />
          <FAQ />

          <p
            className="mt-8 mb-40 text-center font-body text-sm text-ar-dark-gray"
            dangerouslySetInnerHTML={{
              __html: t('plans.terms_agreement', {
                link: 'https://asanarebel.com/terms-of-use/',
                terms_of_service: t('plans.terms_of_service'),
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />
          {plans && plans.length > 0 && (
            <ContinueButton
              id="add_to_cart"
              disabled={loading}
              onClick={() => {
                snowplowTrack({
                  eventName: 'navigation_click',
                  property: 'add_to_cart',
                  label: plans[0]?.name,
                })
                onContinue(plans[0])
              }}
              buttonText={t('plans.call_to_action').toUpperCase()}>
              <span className="mt-3 block w-full text-center font-body text-sm text-ar-dark-gray">
                {t('plans.cancel_anytime')}
              </span>
            </ContinueButton>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-center" transition={Slide} />
    </div>
  )
}
