import { StripePrice } from 'hooks/useApi'

export interface PaymentPlan {
  code: string
  name: string
  intervalLength: number
  currencies: PlanCurrency[]
}

export interface PlanCurrency {
  currency: string
  unitAmount: number
  unitAmountRamp: number
}

// Mapping function to convert Stripe price to common PaymentPlan format
export const mapStripePriceToPlan = (price: StripePrice): PaymentPlan => {
  return {
    code: price.id,
    name: price.product,
    intervalLength: price.recurring.interval === 'year' ? 12 : 1,
    currencies: [
      {
        currency: price.currency,
        unitAmount: price.unit_amount / 100,
        unitAmountRamp: price.second_year_unit_amount
          ? price.second_year_unit_amount / 100
          : 0,
      },
    ],
  }
}
