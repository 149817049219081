import { createContext, useContext, Dispatch, SetStateAction } from 'react'
import { PaymentPlan } from 'types/payment'

interface PlanState {
  plan: PaymentPlan | undefined
  setPlan: Dispatch<SetStateAction<PaymentPlan | undefined>>
}

const PlanContext = createContext({} as PlanState)

export const usePlan = () => useContext(PlanContext)

export default PlanContext
