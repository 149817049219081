import { useEffect, useState } from 'react'
import { Form } from './Form'
import { useUser } from 'context/UserContext'
import { usePlan } from 'context/PlanContext'
import usePlanData from 'hooks/usePlanData'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import useApi from 'hooks/useApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Loading } from './Loading'
import { RoutePaths } from 'route-paths'
import { Slide, toast, ToastContainer } from 'react-toastify'

interface Props {
  locale: string
  nextRoute: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)

export const Checkout: React.FC<Props> = ({ locale, nextRoute }) => {
  const { user, setUser } = useUser()
  const { plan } = usePlan()
  const [subscriptionId, setSubscriptionId] = useState<string>('')
  const [priceId, setPriceId] = useState<string>('')
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan_id')
  const navigate = useNavigate()
  const [stripeCurrency, setStripeCurrency] = useState<string>('')
  const [stripeClientSecret, setStripeClientSecret] = useState<string>('')
  const { currency, country } = usePlanData(locale)
  const { t } = useTranslation()
  const { createStripePaymentIntent } = useApi(locale)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [user])

  useEffect(() => {
    if (!planId && !plan?.code) {
      navigate(RoutePaths.Plans, { replace: true })
    } else if (planId) {
      setPriceId(planId)
    } else if (plan?.code) {
      setPriceId(plan.code)
    }
  }, [plan, planId, navigate])

  useEffect(() => {
    const initializePayment = async () => {
      if (!user?.authToken || !priceId) return

      try {
        const {
          subscription_id: subscriptionId,
          client_secret: clientSecret,
          currency: stripeCurrency,
        } = await createStripePaymentIntent({
          authToken: user.authToken,
          priceId: priceId,
          currency: currency,
        })

        setSubscriptionId(subscriptionId)
        setStripeCurrency(stripeCurrency)
        setStripeClientSecret(clientSecret)

        setUser((prev) => ({
          ...prev,
          stripe: { clientSecret, subscriptionId },
        }))
      } catch (error) {
        toast.error(t('errors.unexpected_error'))
      }
    }

    initializePayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.authToken, priceId])

  if (
    !plan?.code ||
    !currency ||
    !user.authToken ||
    !user.email ||
    !stripeCurrency ||
    !subscriptionId ||
    !stripeClientSecret
  ) {
    return <Loading />
  }

  return (
    <>
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: stripeClientSecret }}>
        <Form
          locale={locale}
          currency={stripeCurrency}
          country={country}
          authToken={user.authToken}
          plan={plan}
          nextRoute={nextRoute}
          subscriptionId={subscriptionId}
        />
      </Elements>
      <ToastContainer position="bottom-center" transition={Slide} />
    </>
  )
}
