import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import useApi from 'hooks/useApi'
import useLocalStorageState from 'use-local-storage-state'
import { PaymentPlan, mapStripePriceToPlan } from 'types/payment'
import { useUser } from 'context/UserContext'

const FALLBACK_CURRENCY = 'usd'
const LOCAL_STORAGE_ALL_PLANS_KEY = 'asanarebel.checkout.stripe.plans'
const LOCAL_STORAGE_SELECTED_PLAN_KEY = 'asanarebel.checkout.stripe.plan'
const LOCAL_STORAGE_CURRENCY_KEY = 'asanarebel.checkout.stripe.currency'
const LOCAL_STORAGE_COUNTRY_KEY = 'asanarebel.checkout.stripe.country'

export default function useStripePlanData(locale: string) {
  const { user } = useUser()
  const { getStripePrices, getGeolocation } = useApi(locale)
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan_id')

  const [selectedPlan, setSelectedPlan] = useLocalStorageState<PaymentPlan>(
    LOCAL_STORAGE_SELECTED_PLAN_KEY,
  )
  const [plans, setPlans] = useLocalStorageState<PaymentPlan[]>(
    LOCAL_STORAGE_ALL_PLANS_KEY,
  )
  const [country, setCountry] = useLocalStorageState<string | undefined>(
    LOCAL_STORAGE_COUNTRY_KEY,
  )
  const [currency, setCurrency] = useLocalStorageState<string>(
    LOCAL_STORAGE_CURRENCY_KEY,
    { defaultValue: FALLBACK_CURRENCY },
  )

  useEffect(() => {
    if (!plans || !planId) {
      return
    }
    const planIdPresent = (plan: PaymentPlan) => plan.code === planId

    if (planId && plans.some(planIdPresent)) {
      setSelectedPlan(plans.find(planIdPresent)!)
    }
  }, [plans, planId, setSelectedPlan])

  useEffect(() => {
    if (plans) {
      return
    }

    const fetchData = async () => {
      try {
        const geolocationResponse = await getGeolocation()
        const stripePricesResponse = await getStripePrices({
          currency: geolocationResponse?.currency || FALLBACK_CURRENCY,
          authToken: user.authToken || '',
        })

        if (!stripePricesResponse.error) {
          const mappedPlans = stripePricesResponse.prices
            .map(mapStripePriceToPlan)
            .sort((a, b) => b.intervalLength - a.intervalLength)
          setPlans(mappedPlans)
          setCountry(geolocationResponse.country_alphabetical_code || '')
          setCurrency(mappedPlans[0].currencies[0].currency)
        } else {
          toast.error(stripePricesResponse.error)
        }
      } catch (error) {
        console.error('Error fetching Stripe prices:', error)
        toast.error('Failed to load plans')
      }
    }

    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    plans,
    country,
    currency,
    selectedPlan,
    setSelectedPlan,
  }
}
